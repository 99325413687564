import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import FilterTop from "../../../assets/shapes/filter-top"
import FilterBottom from "../../../assets/shapes/filter-bottom"
import Button from "../../../components/Button";

import Card from "../../../components/CardMarquee/index";

import { TransComponents } from "../../../local/i18n";
import { useSelector } from 'react-redux';

export default function DocsSection() {
  const { t, i18n } = useTranslation();
  const theme = useSelector((state) => state.theme.mode);
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL_PREFIX}/blog/ghost/api/content/posts?key=${process.env.REACT_APP_CONTENT_API_KEY}&include=tags&filter=tag:hash-` + i18n.language)
      .then(response => response.json())
      .then(data => {
        setDocs(data);
      })
      .catch(error => {
        console.error('Failed to load FAQs', error);
      });
  }, [i18n.language]);

  return (
    <section className="overflow-hidden h-[847px] w-full text-white relative" id="news">
      <img
        src={'/images/bg/DocsBG.webp'}
        alt=""
        className="absolute -z-10 left-0 w-full h-[800px]  hidden md:hidden dark:md:block"
        width={1600}
        height={800}
      />
      <img
        src={'/images/bg/DocsBGLight.webp'}
        alt=""
        className="absolute -z-10 left-0 w-full h-[800px]  hidden md:block dark:md:hidden"
        width={1600}
        height={800}
      />
      <img
        src={'/images/bg/DocsLines.webp'}
        alt=""
        className="w-auto left-0 absolute h-[800px] -z-10 hidden md:hidden dark:md:block"
        height={800}
      />
      <img
        src={'/images/bg/DocsLinesLight.webp'}
        alt=""
        className="w-auto left-0 absolute h-[800px] -z-10 hidden md:block dark:md:hidden"
        height={800}
      />
      <div className="md:h-[800px] containerAlt lg:px-36 md:w-full md:flex md:px-10 md:items-center md:justify-between">
        <img
          src={theme === "light" ? '/images/bg/DocsLinesMobileLight.webp' : '/images/bg/DocsLinesMobile.webp'}
          alt=""
          className="w-auto left-0 absolute object-cover w-full object-center -z-10 md:hidden dark:md:hidden"
        />
        <div className="my-auto px-[15px] py-[45px] pb-[75px] md:px-[0px] md:py-[0px] md:pb-[0px]">
          <div className="max-w-[448px] text-left grid gap-6">
            <h2 className="font-bold text-[24px] leading-[26.4px] md:text-[32px] md:leading-[35.2px] dark:text-white text-black">
              <Trans
                components={{
                  ...TransComponents,
                }}>
                {t("docs-title")}
              </Trans>
            </h2>
            <p className="text-[14px] leading-[18.2px] md:text-[16px] md:leading-[20.8px] dark:text-white text-black">
              <Trans
                components={{
                  ...TransComponents,
                  a: (
                    <a
                      href="https://solar.org"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}>
                {t("docs-desc")}
              </Trans>
            </p>
            <Button
              type="a"
              href={`${process.env.REACT_APP_API_URL_PREFIX}/blog`}
              target="_blank"
              rel="noopener noreferrer"
              hoverImg={"/images/shapes/solar-innovation-hover.svg"}
            >
              {t("discover-all-blog-posts")}
              <span>
                <img
                  src={'/images/icons/arrow-up-right.svg'}
                  alt=""
                  className="bg-white rounded-full"
                />
              </span>
            </Button>
          </div>
        </div>
        {/* animated side */}
        <div className="relative md:h-full bg-[#EFEFEF] dark:bg-[#141418] md:bg-transparent">
          <FilterTop
            color={theme === "light" ? "#EFEFEF" : "#141418"}
            className="absolute w-full h-[80px] z-10 left-0 top-0 md:hidden dark:md:hidden"
          />
          <div className="flex justify-center gap-3 h-[400px] md:h-full overflow-hidden sm:overflow-hidden ">
            <div className="h-full">
              <div className="animate-marqueeY flex flex-col gap-5 pb-5">
                {
                  docs?.posts?.map((card, index) => (<Card index={index} t={t} title={card.title} content={card.excerpt} image={card.feature_image} url={card.url} key={`card-${card.title}`} />
                  ))
                }
              </div>
              <div className="animate-marqueeY flex flex-col gap-5 pb-5">
                {
                  docs?.posts?.map((card, index) => (<Card index={index} t={t} title={card.title} content={card.excerpt} image={card.feature_image} url={card.url} key={`card-${card.title}`} />
                  ))
                }
              </div>
            </div>
            <div>
              <div className="animate-marqueeYRev flex flex-col gap-5 pb-5">
                {
                  docs?.posts?.map((card, index) => (<Card index={index} t={t} title={card.title} content={card.excerpt} image={card.feature_image} url={card.url} key={`card-${card.title}`} />
                  ))
                }
              </div>
              <div className="animate-marqueeYRev flex flex-col gap-5 pb-5">
                {
                  docs?.posts?.map((card, index) => (<Card index={index} t={t} title={card.title} content={card.excerpt} image={card.feature_image} url={card.url} key={`card-${card.title}`} />
                  ))
                }
              </div>
            </div>
          </div>
          <FilterBottom
            color={theme === "light" ? "#EFEFEF" : "#141418"}
            className="absolute w-full h-[80px] z-10 left-0 bottom-0 md:hidden dark:md:hidden"
          />
        </div>
      </div>
    </section>
  );
}
