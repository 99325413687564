import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import closeIcon from "../../assets/icons/close.png";
import Select from "../Select";
import { useSelector, useDispatch } from 'react-redux';
import { toggleOpen } from "../../feature/mode/contactModal";
import { validateEmail } from "../../utils/help";
import { contact_content } from "../../utils/help";

export default function ContactUs() {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.theme.mode);
    const opened = useSelector((state) => state.contact.open);
    const { t, i18n } = useTranslation();
    const [isOpen, setOpen] = useState(opened);
    const [options, setOptions] = useState([]);
    const [name, setName] = useState("");
    const [nameErr, setNameErr] = useState("");
    const [email, setEmail] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [subject, setSubject] = useState("");
    const [subjectErr, setSubjectErr] = useState("");
    const [msg, setMsg] = useState("");
    const [msgErr, setMsgErr] = useState("");
    const validate = (token) => {
        let isValidated = true;
        if (!name) {
            setNameErr("Type your full name.");
            isValidated = false;
        }
        if (!validateEmail(email)) {
            isValidated = false;
            setEmailErr("Type your valid email.");
        }
        if (!subject) {
            isValidated = false;
            setSubjectErr("Select your subject.");
        }
        if (!msg) {
            isValidated = false;
            setMsgErr("Type your message.");
        }
        if (!token) {
            isValidated = false;
        }
        return isValidated;
    }
    useEffect(() => {
        fetch("/subjects-" + i18n.language + ".json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(response => response.json())
            .then(data => {
                setOptions(data)
            })
            .catch(error => {
                console.error('Failed to load subjects', error);
            });
    }, [i18n.language]);

    useEffect(() => {
        setOpen(opened);
    }, [opened]);

    useEffect(() => {
        const loadReCAPTCHAScript = () => {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
            script.async = true;
            document.body.appendChild(script);
        };
        loadReCAPTCHAScript();
    }, []);

    const submit = () => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(async (token) => {
                if (validate(token)) {
                    const content = contact_content(name, email, subject.A, msg);
                    const data = {
                        username: name,
                        sender_email: email,
                        recipient_email: process.env.REACT_APP_SUPPORT_EMAIL_TO,
                        subject: subject.A,
                        email_content: content,
                        recaptcha_token: token,
                        site_key: process.env.REACT_APP_RECAPTCHA_SITE_KEY
                    };
                    try {
                        const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_TYMPT_DEV_URL : process.env.REACT_APP_TYMPT_PRO_URL;
                        const dataOptions = {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json' 
                            },
                            body: JSON.stringify(data) 
                          };
                        const response = await fetch(`${baseURL}/auth/send-email`, dataOptions);
                        console.log('Email sent successfully:', response.data);
                    } catch (error) {
                        console.log(error);
                    }
                }
            });
        });
    }
    return (
        <>
            {isOpen ?
                <div className="fixed z-50 inset-0 flex items-center justify-center overflow-hidden">
                    <div className="fixed inset-0 transition-opacity dark:bg-black bg-gray-500 dark:opacity-50 opacity-75">
                    </div>
                    <div className="rounded-2xl p-6 dark:bg-[#8f8e8e4a] bg-white backdrop-blur-lg backdrop-filter bg-blend-luminosity text-left overflow-visible shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                        <div className="flex items-center justify-between">
                            <h3 className="text-[20px] leading-[24px] font-bold dark:text-white-900">
                                {t("contact-us")}
                            </h3>
                            <img src={closeIcon} alt="" className="w-[20px] h-[20px] cursor-pointer"
                                onClick={() => {
                                    setOpen(false);
                                    dispatch(toggleOpen());
                                    document.body.style.overflow = 'auto';
                                }} />
                        </div>
                        <div className="flex flex-col gap-[20px] mt-[20px]">
                            <div className="flex flex-col gap-[5px]">
                                <input type="text" className="dark:bg-[#0d0d0d66] border dark:border-0 rounded-md dark:text-white text-black font-normal leading-6 py-1.5 px-3 transition duration-150 ease-in-out dark:placeholder-[#FFFFFF4D] placeholder-[#0000004D]" placeholder={t("name")} onChange={(e) => {
                                    setName(e.target.value);
                                    if (e.target.value.length !== 0) {
                                        setNameErr(null)
                                    }
                                }} value={name} />
                                {nameErr && <label className="text-[13px] text-red-500">{nameErr}</label>}
                            </div>
                            <div className="flex flex-col gap-[5px]">
                                <input type="text" className="dark:bg-[#0d0d0d66] border dark:border-0 rounded-md dark:text-white text-black font-normal leading-6 py-1.5 px-3 transition duration-150 ease-in-out dark:placeholder-[#FFFFFF4D] placeholder-[#0000004D]" placeholder={t("email")} value={email} onChange={(e) => {
                                    setEmail(e.target.value);
                                    if (validateEmail(e.target.value)) {
                                        setEmailErr(null);
                                    }
                                }} />
                                {emailErr && <label className="text-[13px] text-red-500">{emailErr}</label>}
                            </div>
                            <div className="flex flex-col gap-[5px]">
                                <Select
                                    value={subject}
                                    setSelect={(e) => {
                                        setSubject(e);
                                        setSubjectErr(null);
                                    }}
                                    options={options}
                                    placeholder={t("subject")}
                                    isDarkMode={theme === 'dark'}
                                />
                                {subjectErr && <label className="text-[13px] text-red-500">{subjectErr}</label>}
                            </div>
                            <div className="flex flex-col gap-[5px]">
                                <textarea className="dark:bg-[#0d0d0d66] rounded-md border dark:border-0 dark:text-white text-black font-normal leading-6 py-1.5 px-3 transition transition-colors transition-shadow duration-150 ease-in-out dark:placeholder-[#FFFFFF4D] placeholder-[#0000004D]" rows={3} value={msg} onChange={(e) => {
                                    setMsg(e.target.value);
                                    if (e.target.value.length !== 0) {
                                        setMsgErr(null);
                                    }
                                }} placeholder={t("your-message")} />
                                {msgErr && <label className="text-[13px] text-red-500">{msgErr}</label>}
                            </div>
                            <button className={`w-full h-[48px] justify-center text-[16px] leading-[16px] bg-theme-orange font-semibold text-white rounded-full py-2 pl-[20px] pr-2 flex gap-4 items-center`} onClick={submit}>{t("submit")}</button>
                        </div>
                    </div>
                </div>
                :
                null
            }
        </>
    )
}