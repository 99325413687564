import clsx from "clsx";
import { useRef } from "react";
import { useInView, motion } from "framer-motion";
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from "react-i18next";
import { TransComponents } from "../../../local/i18n.js";
import { StakeData } from "../../../config/const.js";
import Arrow from "../../../assets/icons/arrow.jsx";

const hoverGradient = `radial-gradient(63.27% 80.37% at 111.3% 3.79%, rgba(237, 92, 21, 0.5), rgba(237, 92, 21, 0.2)), radial-gradient(at left bottom, rgba(237, 92, 21, 0.1), rgba(237, 92, 21, 0)), radial-gradient(at top, rgba(237, 92, 21, 0.1), rgba(237, 92, 21, 0))`;

export default function StakeSection() {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const theme = useSelector((state) => state.theme.mode);
  const { t, i18n } = useTranslation();

  return (
    <section className="!px-0 md:py-5 !max-w-fit section" ref={ref} id="products">
      <img
        src={'/images/shapes/stake.svg'}
        alt=""
        className="absolute -z-10 h-[76.59vw] object-cover right-0 hidden md:hidden dark:md:block"
      />
      <img
        src={'/images/shapes/stake-light.svg'}
        alt=""
        className="absolute -z-10 h-[76.59vw] object-cover right-0 hidden md:block dark:md:hidden"
      />
      <img
          src={'/images/shapes/stake-mobile.svg'}
          alt=""
          className="absolute -z-10 mt-[200px] object-cover right-0 md:hidden dark:md:hidden"
        />
      <h1 className="flex justify-center gap-3 md:gap-5 mb-10 leading-[35.2px] md:leading-[47.52px] font-bold text-[32px] sm:text-[32px] md:text-[48px] lg:text-[64px]">
        <Trans
            components={{
              ...TransComponents
            }}>
            {t("our-products")}
        </Trans>
      </h1>
      <motion.section
        className="lg:flex grid gap-4 flex-wrap justify-center items-center md:grid md:grid-cols-1"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={inView ? { scale: 1, opacity: 1 } : { scale: 0.9, opacity: 0 }}
        transition={{ duration: 0.6 }}>
        {StakeData.map(
          (
            {
              name,
              desc,
              imageSrc,
              imageLightSrc,
              imageMobileSrc,
              imageMobileLightSrc,
              href,
              bulletPoints,
              isBig,
              CustomFooter,
            },
            index
          ) => (
            <Card
              theme={theme}
              name={name}
              desc={desc}
              href={href}
              isBig={isBig}
              imageSrc={imageSrc}
              imageLightSrc={imageLightSrc}
              imageMobileSrc={imageMobileSrc}
              imageMobileLightSrc={imageMobileLightSrc}
              bulletPoints={bulletPoints}
              CustomFooter={CustomFooter}
              key={index}
              hoverGradient={hoverGradient}
            />
          )
        )}
      </motion.section>
    </section>
  );
}

const Card = ({
  theme,
  name,
  desc,
  imageSrc,
  imageLightSrc,
  imageMobileSrc,
  imageMobileLightSrc,
  href,
  bulletPoints,
  isBig,
  CustomFooter,
  hoverGradient,
}) => {
  const { t } = useTranslation();
  return (
    <motion.div
      whileHover={{
        scale: 0.99,
        backgroundImage: hoverGradient,
        transition: { duration: 0.1, ease: "easeInOut" },
      }}
      className={`p-[3px] rounded-[32px] text-white cursor-pointer dark:bg-initial-gradient bg-initial-gradient-light`}>
      <div
        className={clsx(
          "relative rounded-[32px] overflow-hidden dark:bg-theme-bg bg-[#FFFFFF] md:w-[60vw]",
          isBig ? "lg:w-[43.75vw]" : "lg:w-[32.125vw]"
        )}>
        <img
          src={imageSrc}
          alt=""
          className="w-full object-cover h-full object-center hidden md:hidden dark:md:block"
        />
        <img
          src={imageLightSrc}
          alt=""
          className="w-full object-cover h-full object-center hidden md:block dark:md:hidden"
        />
        <img
          src={theme === "light" ? imageMobileLightSrc: imageMobileSrc}
          alt=""
          className="w-full object-cover h-full object-center md:hidden dark:md:hidden"
        />

        <div className="px-6 py-6 bottom-0 absolute grid gap-4 w-full">
          <div className="text-[10px] leading-[13px] md:text-[12px] md:leading-[15.6px] border border-theme-orange text-xs rounded-full py-2 px-3 w-fit dark:bg-[#0C0C0F] bg-[#FFFFFF] dark:text-white text-black">
            <span className="h-1 w-1 md:h-2 md:w-2 bg-theme-orange rounded-full inline-block mr-2" />
            {t(desc)}
          </div>
          <h3 className="font-bold text-[24px] leading-[23.76px] md:text-[32px] md:leading-[31.68px] dark:text-white text-black">
            {t(name)}
          </h3>
          <ul className="text-base mb-4 opacity-80">
            {bulletPoints.map((desc, index) => (
              <li
                key={index}
                className="flex gap-2 mb-1 dark:text-white text-black text-[16px] leading-[20.8px]">
                <img src={"/images/shapes/star.svg"} alt="" className="dark:block hidden" />
                <img src={"/images/shapes/star-light.svg"} alt="" className="dark:hidden" />
                {t(desc)}
              </li>
            ))}
          </ul>
          {href && (
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-theme-orange flex justify-between w-full items-center font-bold outline-none">
              {t("visit-website")}
              <Arrow className="hover:dark:fill-white hover:fill-theme-bg fill-theme-orange" />
            </a>
          )}
          {CustomFooter && CustomFooter}
        </div>
      </div>
    </motion.div>
  );
};
