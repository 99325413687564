import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ArrowIcon from "../../../assets/icons/arrow";
import { useParams, Link } from "react-router-dom";
import { FooterData } from "../../../config/const";
import formatDate from "../FormatDate";
import HeroBackground from "../../../assets/backgrounds/hero.svg";
import parse, { domToReact } from "html-react-parser";

export default function BlogDetails() {
  const { t, i18n } = useTranslation();
  const [blogDetails, setBlogDetails] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const { slug } = useParams();
  const [docs, setDocs] = useState([]);
  const [topicIndex, setTopicIndex] = useState(0);

  // State to hold refs for each <strong> tag in the HTML content
  const topics = useRef([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [window.location.href])

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL_PREFIX}/blog/ghost/api/content/posts/slug/` +
      slug +
      `/?key=${process.env.REACT_APP_CONTENT_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(slug, "slug");
        setBlogDetails(data);

        const parser = new DOMParser();
        const doc = parser.parseFromString(data.posts[0].html, "text/html");

        const headers = doc.querySelectorAll("h2");
        topics.current = Array.from(headers).map((header) => ({
          id: header.id,
          topic: header.textContent.trim(),
        }));
      })
      .catch((error) => {
        console.error("Failed to load FAQs", error);
      });
  }, [slug]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL_PREFIX}/blog/ghost/api/content/posts?key=449fe14d8a65eb3884a657f532&include=tags&filter=tag:hash-` +
      i18n.language
    )
      .then((response) => response.json())
      .then((data) => {
        setDocs(data);
      })
      .catch((error) => {
        console.error("Failed to load FAQs", error);
      });
  }, [i18n.language]);

  function scrollToContent(sectionId) {
    const offset = document.getElementsByTagName("nav")[0].clientHeight;
    const section = document.getElementById(sectionId);
    const sectionPosition =
      section.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = sectionPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  // const transform = (node, index) => {
  //   if (node.type === "tag" && node.name === "h2" && node.attribs.id) {
  //     return (
  //       <h2 {...node.attribs} ref={strongRefs.current[index]}>
  //         {domToReact(node.children, transform)}
  //       </h2>
  //     );
  //   }
  // };

  return (
    <div className="containerAlt px-5 md:px-5 sb:px-25 lg:px-40 xl:px-45 justify-between w-full mx-auto pt-6 md:pt-6 containerAlt sm:pt-[30px] ">
      <img
        src={HeroBackground}
        alt=""
        className="absolute w-full -z-10 object-cover top-0 left-0 md:block hidden"
      />
      <div className="hidden sm:flex">
        <div>
          <span className="font-nunito text-sm font-normal leading-[130%] opacity-40">
            Blog /{" "}
          </span>
          <span> {blogDetails.posts && blogDetails.posts[0].title}</span>
        </div>
      </div>
      <div className="sm:flex sm:py-12">
        <div className="hidden sm:block sm:max-w-[292px]">
          <div className="pb-[120px] gap-2.5 font-normal leading-110 fill-theme-bg dark:fill-white">
            {topics.current.length > 0 &&
              topics.current.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className={`h-10 pl-8 items-center content-center self-stretch cursor-pointer hover:text-theme-orange hover:opacity-100 ${topicIndex == index ? 'opacity-100 border-l-2 text-theme-orange border-theme-orange' : 'opacity-30'}`}
                    onClick={() => {
                      scrollToContent(topic.id);
                      setTopicIndex(index);
                    }}
                  >
                    {topic.topic}
                  </div>
                );
              })}
          </div>
          <div className="leading-110 border border-black/10 dark:border-white/10 rounded-3xl dark:bg-theme-bg bg-[#E8E8E8] py-4">
            <div className="text-[28px] lg:text-3xl text-center">
              <span>Subscribe on</span>
              <br />
              <span style={{ color: "rgb(237 92 21)" }} className="font-bold">
                Brighter Blog
              </span>
            </div>
            <div className="rounded-3xl px-4 pt-2">
              <div className="items-center border border-black/10 dark:border-white/10 rounded-full dark:bg-theme-bg bg-[#E8E8E8] flex">
                <input
                  type="text"
                  className="text-4 pl-4 bg-transparent outline-none w-full hover:placeholder:text-white dark:text-white text-black z-[1]"
                  placeholder={isFocused ? "" : t("join-community-input")}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
                <div className="flex items-center justify-end h-[40px] w-[40px] rounded-full">
                  <div className="bg-[#ED5C15]  rounded-full h-[40px] w-[40px]  justify-center items-center flex dark:group-hover:bg-white group-hover:bg-theme-orange">
                    <ArrowIcon className="h-[24px] w-[24px] md:w-[24px] md:h-[24px] fill-theme-white" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="items-center ">
            <div className="text-center py-6 font-nunito text-base font-bold leading-[130%] opacity-[40%]">
              Subscribe on our social media
            </div>
            <div className="justify-center items-center flex gap-2">
              {FooterData.socials.map(({ href, Icon }, index) => (
                <a
                  href={href}
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon className="fill-theme-bg dark:fill-white hover:fill-theme-orange dark:hover:fill-theme-orange" />
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="sm:pl-6 lg:pl-8 xl:pl-10 2xl:pl-14 lg:w-[900px]">
          <div className="sm:flex justify-between ">
            <div className="flex gap-2 items-center py-2">
              <img
                src={"/images/icons/24x24_solar.svg"}
                alt=""
                className="h-8 w-8 object-cover object-center rounded-lg hover:cursor-pointer"
              />
              <div className="text-[#ED5C15]">By Solar Enterprises</div>
            </div>
            <div className="flex gap-6 items-center ">
              <img
                src="/images/icons/category.svg"
                alt=""
                className="h-8 w-25 object-cover object-center rounded-lg hover:cursor-pointer"
              />
              <div className="font-nunito text-sm font-normal  leading-[130%] opacity-40">
                {blogDetails.posts &&
                  formatDate(blogDetails.posts[0].published_at)}
              </div>
            </div>
          </div>

          <div className="font-nunito text-[32px] font-bold leading-[99%] tracking-[-0.64px] pt-4">
            {blogDetails.posts && blogDetails.posts[0].title}
          </div>
          <div className="py-4">
            <img
              src={blogDetails.posts && blogDetails.posts[0].feature_image}
              alt=""
              className="w-[335px] mx-auto h-[188px] md:w-full md:h-[460px] object-cover object-center rounded-2xl hover:cursor-pointer"
            />
          </div>
          <div className="sm:hidden border border-black/10 dark:border-white/10 rounded-3xl dark:bg-theme-bg bg-[#E8E8E8]">
            <div className="leading-[110%] pt-4 text-[28px] sm:text-xl md:text-[28px] lg:text-3xl text-center">
              <span>Subscribe on</span>
              <br />
              <span style={{ color: "rgb(237 92 21)" }} className="font-bold">
                Brighter Blog
              </span>
            </div>
            <div className="rounded-3xl p-4">
              <div className="items-center border border-black/10 dark:border-white/10 rounded-full dark:bg-theme-bg bg-[#E8E8E8] flex">
                <input
                  type="text"
                  className="text-base pl-4 bg-transparent outline-none w-full hover:placeholder:text-white dark:text-white text-black z-[1]"
                  placeholder={isFocused ? "" : t("join-community-input")}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
                <div className="flex items-center justify-end h-[40px] w-[40px] rounded-full">
                  <div className="dark:bg-[#ED5C15] bg-[#FFFFFF] rounded-full h-[40px] w-[40px]  justify-center items-center flex dark:group-hover:bg-white group-hover:bg-theme-orange">
                    <ArrowIcon className="h-[24px] w-[24px] md:w-[24px] md:h-[24px] dark:block hidden fill-theme-white" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="gh-content gh-canvas is-body drop-cap ">
            {blogDetails.posts &&
              // parse(blogDetails.posts[0].html, { replace: transform })}
              parse(blogDetails.posts[0].html)}
          </section>
          {/* // dangerouslySetInnerHTML={
            //   blogDetails.posts && { __html: blogDetails.posts[0].html ?? "" }
            // } */}
          <div className="py-6">
            <div className="px-6 py-4 lg:p-8 rounded-2xl border-white bg-white/3 backdrop-blur-sm text-white sm:flex items-center justify-between border">
              <div className="font-nunito text-center sm:text-left">
                <div className="text-2xl lg:text-4xl font-bold leading-[110%] text-[#ED5C15]">
                  Thanks for Reading!
                </div>
                <div className="text-sm  py-2 lg:text-base font-normal leading-[130%]">
                  Do you like this article? Please share!
                </div>
              </div>
              <div className="flex gap-2 items-center justify-center">
                {FooterData.socials.map(({ href, Icon }, index) => (
                  <a
                    href={href}
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon className="fill-theme-bg dark:fill-white hover:fill-theme-orange dark:hover:fill-theme-orange rounded-full border border-white bg-white/3 backdrop-blur-sm" />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="font-nunito text-[32px] font-normal leading-[100%]">
        You may also like
      </div>

      <div className="sm:grid sm:grid-cols-4 sm:gap-14 sm:pt-12 flex overflow-x-auto pt-6">
        {docs.posts &&
          docs.posts.map(
            (post, index) => (
              index > 0,
              index < 4 ? (
                <Link to={`/blogs/${post.slug}`} key={index}>
                  <div
                    key={index}
                    className="p-1 min-w-[260px] hover:rounded-[16px] hover:bg-white/10 align-center"
                  >
                    <div className="relative">
                      <img
                        src={post.feature_image}
                        alt=""
                        className="mx-auto w-[260px] h-[180px] md:w-[290px] object-cover object-center rounded-lg hover:cursor-pointer"
                      />
                    </div>
                    <div className="flex flex-col min-h-[150px] items-between justify-between pt-6">
                      <div className="md:py-4">
                        <div className="text-[14px] font-normal leading-130 opacity-40">
                          {formatDate(post.published_at)}
                        </div>
                        <div className="text-lg md:text-2xl lg:text-[32px] line-clamp-2 overflow-hidden text-white text-ellipsis font-nunito lg:text-4xl sm:font-bold leading-[1.01] tracking-[-0.32px]">
                          {post.title}
                        </div>
                        <div
                          className="line-clamp-2 text-sm opacity-80"
                          dangerouslySetInnerHTML={{ __html: post.excerpt ?? "" }}
                        />
                      </div>
                      <div className="flex gap-4 ">
                        <img
                          src="/images/icons/24x24_solar.svg"
                          alt=""
                          className="h-[24px] w-[24px] object-cover object-center rounded-lg hover:cursor-pointer"
                        />
                        <div className="text-[#ED5C15] font-nunito text-bold-16">
                          By Solar Enterprises
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ) : null
            )
          )}
      </div>
    </div>
  );
}
