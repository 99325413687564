import React, { Children } from "react";
import { Outlet } from "react-router-dom";
import Drawer from "./components/Header/Drawer";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/Home";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <div>
        <Header setIsOpen={setIsOpen} />
        <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />
        {children}
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

export default Layout;
