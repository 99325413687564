import React from "react";
import { useTranslation } from "react-i18next";

export default function MiddleArea() {
  const { t } = useTranslation();
  const scrollDown = () => {
    window.scrollTo({
      top: window.scrollY + window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <section className="section flex flex-col items-center">
      <div className="relative w-fit mx-auto md:-mt-20 -z-10">
        <img src={'/images/bg/hero-banner.webp'} alt="" className="h-auto md:block hidden w-[46vw]"/>
        <img src={'/images/bg/hero-banner-mobile.webp'} alt="" className="w-full h-auto md:hidden ml-[25px] scale-125"/>
        <img
          src={'/images/bg/dash.webp'}
          alt=""
          className="absolute w-[137px] top-[80px] -left-[0px] md:top-60 md:top-[15vw] md:-left-24 animate-GFG h-auto md:w-[26vw]"
        />
        <img
          src={'/images/bg/district.webp'}
          alt=""
          className="absolute w-[130px] bottom-[25px] left-[-0px] md:bottom-10 md:bottom-[5vw] md:left-5 animate-GFGRev h-auto md:w-[14.5vw]"
        />
        {/* Right Side */}
        <>
          <img
            src={'/images/bg/credit-card-dark.webp'}
            alt=""
            className="absolute w-[154px] top-[50px] right-[0px] md:top-60 md:right-0 md:top-[15vw] animate-GFGRev hidden dark:block  h-auto md:w-[19.8vw]"
          />
          <img
            src={'/images/bg/credit-card.webp'}
            alt=""
            className="absolute w-[154px] top-[50px] right-[0px] md:top-60 md:right-0 md:top-[15vw] animate-GFGRev dark:hidden block  h-auto md:w-[19.8vw]"
          />
        </>
        {/* Left Side */}
        <>
          <img
            src={'/images/bg/credit-card1-dark.webp'}
            alt=""
            className="absolute w-[146px] bottom-10 bottom-[30px] right-[0px] md:bottom-[5vw] md:right-5 animate-GFG hidden dark:block  h-auto md:w-[21.3vw]"
          />
          <img
            src={'/images/bg/credit-card1.webp'}
            alt=""
            className="absolute w-[146px] bottom-10 bottom-[30px] right-[0px] md:bottom-[5vw] right-5 animate-GFG dark:hidden block h-auto md:w-[21.3vw]"
          />
        </>
      </div>
      <button
        className="flex flex-col justify-center items-center gap-3 mt-10 text-center"
        onClick={scrollDown}>
        <span className="dark:text-white text-black">{t("scroll-down")}</span>
        <svg
          width="16"
          height="13"
          viewBox="0 0 16 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.9536 5.47928C15.2964 5.83918 15.2825 6.40887 14.9226 6.7517L8.62214 12.7533C8.2745 13.0844 7.7282 13.0844 7.3806 12.7533L1.08003 6.7517C0.720131 6.40887 0.706283 5.83918 1.04911 5.47928C1.39195 5.11937 1.96163 5.10553 2.32153 5.44835L8.00138 10.8586L13.6811 5.44835C14.0411 5.10553 14.6107 5.11937 14.9536 5.47928ZM14.9536 0.679278C15.2964 1.03918 15.2825 1.60887 14.9226 1.9517L8.62214 7.95331C8.2745 8.28439 7.7282 8.28439 7.3806 7.95331L1.08003 1.9517C0.720131 1.60887 0.706283 1.03918 1.04911 0.679278C1.39195 0.319374 1.96163 0.305526 2.32153 0.648354L8.00138 6.05866L13.6811 0.648354C14.0411 0.305526 14.6107 0.319374 14.9536 0.679278Z"
            fill="currentColor"
            className="dark:fill-white fill-black"
          />
        </svg>
      </button>
    </section>
  );
}
