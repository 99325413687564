import Logo from "../../assets/logo.png";
import LogoLight from "../../assets/logo-light.png";
import { FooterData } from "../../config/const";
import { useRef } from "react";
import { useInView, motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const { t } = useTranslation();
  return (
    <footer
      className="containerAlt px-5 pt-[45px] mt-[20px] md:px-36 md:pt-16 md:mt-40 dark:bg-theme-bg grid gap-5"
      ref={ref}>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="grid gap-2">
          <a className="dark:block hidden">
            <img src={Logo} alt="" width={117.96} height={28.32} />
          </a>
          <a className="dark:hidden">
            <img src={LogoLight} alt="" width={117.96} height={28.32} />
          </a>
          <a
            href={`mailto:${t("footer-email")}`}
            className="font-medium text-sm cursor-pointer hover:text-theme-orange">
            {t("footer-email")}
          </a>
          <div className="flex gap-2 items-center">
            {FooterData.socials.map(({ href, Icon }, index) => (
              <a
                href={href}
                key={index}
                target="_blank"
                rel="noopener noreferrer">
                <Icon className="fill-theme-bg dark:fill-white hover:fill-theme-orange dark:hover:fill-theme-orange" />
              </a>
            ))}
          </div>
        </div>
        <div className="mt-[40px] md:mt-[0px] gap-5 flex flex-col md:flex-row md:gap-20 text-[#A7A7A7]">
          <div className="flex flex-col gap-1">
            <span className="text-base font-bold dark:text-white text-black">
              {t("about-us")}
            </span>
            {FooterData.AboutUs.map(({ href, title }, index) => (
              <a
                href={href}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-links">
                {t(title)}
              </a>
              // <p key={index}>{t(title)}</p>
            ))}
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-base font-bold dark:text-white text-black">
              {t("products")}
            </span>
            {FooterData.Products.map(({ href, title }, index) => (
              <a
                href={href}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-links">
                {t(title)}
              </a>
            ))}
          </div>
          {/* <div className="flex flex-col gap-1">
            <span className="text-base font-bold dark:text-white text-black">
              {t("blog")}
            </span>
            {FooterData.Blog.map(({ href, title }, index) => (
              <a
                href={href}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-links">
                {t(title)}
              </a>
            ))}
          </div> */}
        </div>
      </div>

      <p className="text-xs font-normal text-[#828282]">
        {t("footer-copy-1")}
        <br />
        <br />
        {t("footer-copy-2")}
      </p>
      <div className="text-xs font-normal text-[#828282] flex flex-col md:flex-row gap-3 md:gap-0 justify-between">
        <div className="flex gap-3">
          {FooterData.copyrightPages.map(({ title, href, target, rel }, index) => (
            index == 1 && <a
              href={href}
              key={index}
              target={target}
              rel={rel}
              className="hover:dark:text-white hover:text-black underline">
              {t(title)}
            </a>
          ))}
        </div>
        <p>{t("footer-copyright")}</p>
      </div>

      <div className="relative h-[71.86px] md:h-[224px] overflow-hidden">
        <motion.div
          initial={{ opacity: 0, y: 300 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1.5 }}
          className="absolute">
          <img src={"/images/bg/footer-image.webp"} alt="" width={1200} height={224} />
          <div
            className="absolute top-0 left-0 w-full h-full hidden dark:block"
            style={{
              background:
                "linear-gradient(180deg, rgba(12, 12, 15, 0.31) 0%, #0C0C0F 100%)",
            }}
          />
          <div
            className="absolute top-0 left-0 w-full h-full block dark:hidden"
            style={{
              background:
                "linear-gradient(180deg, rgba(255, 255, 255, 0.31) 0%, #FFFFFF 100%)",
            }}
          />
        </motion.div>
      </div>
    </footer>
  );
}
