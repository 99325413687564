import { useRef, useState } from "react";
import { useInView, motion } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";
import Button from "../../../components/Button";

import { OurImpactData } from "../../../config/const";
import { TransComponents } from "../../../local/i18n";
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

export default function OurImpactSection() {
  const ref = useRef(null);
  const blockRef = useRef(null);
  const scrollableElementRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const blockInView = useInView(blockRef, { once: true });
  const { t, i18n } = useTranslation();
  const theme = useSelector((state) => state.theme.mode);

  return (
    <div className="relative py-1 md:py-16 px-[15px]">
      <section className="text-center snap-center">
      
        <h2 className="font-bold text-[32px] leading-[35.2px] md:text-[48px] md:leading-[47.52px] mb-8">
          <Trans components={TransComponents}>{t("our-impact-title")}</Trans>
        </h2>
        <p className="font-normal text-base md:text-[16px] md:leading-[20.8px] text-[14px] leading-[18.2px] max-w-[714px] mx-auto mb-8">
          <Trans
            components={{
              ...TransComponents,
              a: (
                <a href="https://solar.org" target="_blank" rel="noreferrer" />
              ),
            }}>
            {t("our-impact-desc")}
          </Trans>
        </p>
        <Button 
          type="a"
          ref={ref}
          href={OurImpactData.btnHref}
          target="_blank"
          rel="noopener noreferrer"
          hoverImg={i18n.language === "jp"? "/images/shapes/solar-innovation-hover.svg": "/images/shapes/inspired-hover.svg"}
        >
          {t("discover")}
          <span>
            <img src={'/images/icons/arrow-up-right.svg'} alt="" className="bg-white rounded-full" />
          </span>
        </Button>
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={blockInView ? { opacity: 1, scale: 1 } : {}}
          transition={{ duration: 0.9 }}
          className="flex flex-col md:flex-row justify-center items-center w-full gap-3 md:mb-[100px] md:mt-20 mt-[40px]"
          ref={blockRef}>
          {OurImpactData.cards.map(
            ({ icon, iconLight, title, desc }, index) => {
              return (
                <div
                  className="flex justify-center items-center sm:max-w-[500px]"
                  key={index}>
                  <img src={icon} alt="" className="w-[100px] md:w-[134px] dark:block hidden" />
                  <img src={iconLight} alt="" className="w-[100px] md:w-[134px] dark:hidden" />

                  <div className="flex flex-col gap-2 items-start text-left">
                    <h3 className="text-[20px] leading-[24px] font-bold">{t(title)}</h3>
                    <p className="text-[12px] leading-[14.4px] md:text-[14px] md:leading-[16.8px]">{t(desc)}</p>
                  </div>
                </div>
              );
            }
          )}
        </motion.div>
        
        <div className="relative">
          <img
            src={'/images/bg/OurImpactLines.webp'}
            alt=""
            className="left-1/2 -translate-x-1/2 impact-ellipse absolute -z-10 hidden md:hidden dark:md:block"
          />
          <img
            src={'/images/bg/OurImpactLinesLight.webp'}
            alt=""
            className="left-1/2 -translate-x-1/2 impact-ellipse absolute -z-10 hidden md:block dark:md:hidden"
          />
          <img
            src={theme == "dark"? '/images/bg/OurImpactLinesMobile.webp': '/images/bg/OurImpactLinesMobileLight.webp'}
            alt=""
            className="left-1/2 -translate-x-1/2 impact-ellipse absolute -z-10 md:hidden dark:md:hidden"
          />

          <div
            className="overflow-auto hidden md:block h-[477px] md:h-[666px] snap-y snap-mandatory scrollbar-none"
            ref={scrollableElementRef}
            onScroll={e => {
              const scrollableElement = scrollableElementRef.current;
              if (
                scrollableElement.scrollHeight - scrollableElement.scrollTop ===
                scrollableElement.clientHeight
              ) {
                // If so, scroll the entire page by 100 pixels (adjust as needed)
                window.scrollBy({ top: 200, behavior: "smooth" });
              }
            }}>
            {OurImpactData.impacts.map(({ heading, icon }, index) => {
              return (
                <div className="w-full relative snap-center h-full" key={index}>
                  <img
                    src={icon}
                    alt=""
                    className="w-[365px] sm:w-[365px] md:w-[666px] object-cover object-center mx-auto absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                  />
                  <div className="absolute top-0 left-0 h-full w-full bg-filter dark:block hidden" />
                  <div
                    className="absolute top-0 left-0 h-full w-full block dark:hidden"
                    style={{
                      background:
                        "linear-gradient(180deg, rgba(255, 255, 255, 0.31) 0%, #FFFFFF 100%)",
                    }}
                  />
                  <h2 className="w-full text-[32px] leading-[35.2px] md:text-[54px] md:leading-[70.2px] bottom-0 left-1/2 absolute bg-theme-gradient -translate-x-1/2 text-transparent bg-clip-text font-semibold">
                    {t(heading)}
                  </h2>
                </div>
              );
            })}
          </div>
          <div className="relative block md:hidden grid gap-12 md:gap-4 w-full h-[400px]">
            <Swiper
              spaceBetween={1}
              onSwiper={swiper => setSwiper(swiper)}
              slidesPerView={1}
              navigation={true}
              modules={[Navigation]}
              className={"mySwiper"}
              >
                {OurImpactData.impacts.map(({ heading, icon }, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <MobileSwiper
                        heading={heading}
                        t={t}
                        icon={icon}
                        key="index"
                      />
                    </SwiperSlide>
                  )
                })}
            </Swiper>
          </div>
        </div>
      </section>
      <img
        src={"/images/shapes/Ellipse 2.svg"}
        alt=""
        className="impact-ellipse absolute left-0 top-0 h-[887.85px] "
      />
      <img
        src={"/images/shapes/Ellipse 3.svg"}
        alt=""
        className="impact-ellipse absolute right-0 top-1/3 h-[887.85px] "
      />
    </div>
  );
}

function MobileSwiper({ heading, icon, index, t }) {
  return (
    <div className="w-full relative snap-center h-full" key={index}>
      <img
        src={icon}
        alt=""
        className="w-full !object-contain mx-auto absolute"
      />
      <div
        className="absolute top-0 left-0 h-full w-full block dark:hidden"
        style={{
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.31) 0%, #FFFFFF 100%)",
        }}
      />
      <h2 className="w-full text-[32px] leading-[35.2px] md:text-[54px] md:leading-[70.2px] bottom-0 left-1/2 absolute bg-theme-gradient -translate-x-1/2 text-transparent bg-clip-text font-semibold">
        {t(heading)}
      </h2>
    </div>
  );
}

