import React, {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import Article from "./Article";
import SearchList from "./SearchList";
import EnterEmail from "./EnterEmail";

export default function News() {
  const { i18n } = useTranslation();
  const [searchWord, setSearchWord] = useState("");
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    console.log("url", process.env.API_URL_PREFIX)
    fetch(
      `${process.env.REACT_APP_API_URL_PREFIX}/blog/ghost/api/content/posts?key=${process.env.REACT_APP_CONTENT_API_KEY}&include=tags&filter=tag:hash-` +
        i18n.language+"&page=1&limit=15"
    )
      .then((response) => response.json())
      .then((data) => {
        setDocs(data);
      })
      .catch((error) => {
        console.error("Failed to load FAQs", error);
      });
  }, [i18n.language]);
  return (
    <div>
      <EnterEmail />
      {docs?.posts?.length && <Article docs={docs}/>}
      {docs?.posts?.length && <SearchList docs={docs} searchWord={searchWord}/>}
    </div>
  );
}
