// CategoryTabs.jsx
import React, { useMemo, useEffect, useState } from "react";

const categories = ["All", "Category 1", "Category 2", "Category 3"];

const CategoryTabs = ({ activeCategory, setCategory }) => {
  const [categories, setCagetories] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL_PREFIX}/blog/ghost/api/content/tags?key=${process.env.REACT_APP_CONTENT_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        let categoryArray = ["All"];
        data.tags.map((tag) => {
          categoryArray.push(tag.name);
          });
        setCagetories(categoryArray);
      })
      .catch((error) => {
        console.error("Failed to load FAQs", error);
      });
  }, []);

  return (
    <div className="flex space-x-4 my-4 flex-wrap">
      {categories.map((category, index) => (
        <button
          key={index}
          className={`py-2 px-4 rounded-full text-white ${
            activeCategory === category
              ? "bg-theme-orange"
              : "hover:bg-white/10"
          }`}
          onClick={() => setCategory(category)}
        >
          {category}
        </button>
      ))}
    </div>
  );
};

export default CategoryTabs;
