import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import formatDate from "../FormatDate/index.jsx";
import { Link } from "react-router-dom";
import ArrowIcon from "../../../assets/icons/arrow";
import { FaSearch } from "react-icons/fa";
import CategoryTabs from "./CategoryTabs";

export default function SearchList({ docs }) {
  const limit = 15;
  const [searchDocs, setSearchDocs] = useState(docs);
  const [activePage, setActivePage] = useState(1);
  const [isFocused, setIsFocused] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("All");

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL_PREFIX}/blog/ghost/api/content/posts?key=${process.env.REACT_APP_CONTENT_API_KEY}&include=tags&filter=html:~'${
        keyword ? keyword : " "
      }'&tag:hash-en&page=${activePage}&limit=${limit}`
    )
      .then((response) => response.json())
      .then((data) => {
        setSearchDocs(data);
      })
      .catch((error) => {
        console.error("Failed to load FAQs", error);
      });
  }, [activePage, keyword]);

  useEffect(() => {
    setActivePage(1);
  }, [keyword]);

  const renderBlogSections = () => {
    const sections = [];
    const posts = searchDocs.posts.slice(0, limit) || [];

    for (let i = 0; i < posts.length; i += 5) {
      const firstTwoPosts = posts.slice(i, i + 2);
      const nextThreePosts = posts.slice(i + 2, i + 5);

      sections.push(
        <div key={`section-${i}`}>
          {/* Grid for first 2 blog posts */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 ">
            {firstTwoPosts.map((post, index) => (
              <Link to={`/blogs/${post.slug}`} key={index}>
                <div className="relative sm:flex sm:gap-6 hover:rounded-[8px] p-1 hover:bg-white/10">
                  <img
                    src="/images/icons/category.svg"
                    alt=""
                    className="absolute pl-4 pt-4 object-cover object-center rounded-lg hover:cursor-pointer"
                  />
                  <img
                    src={post.feature_image}
                    alt=""
                    className="h-[180px] w-[343px] md:w-[180px] xl:w-[290px] object-cover object-center rounded-[10px] hover:cursor-pointer"
                  />
                  <div className="flex flex-col items-start justify-between">
                    <div className="py-4 sm:py-0">
                      <div className="text-[14px] font-normal leading-130 opacity-40">
                        {formatDate(post.published_at)}
                      </div>
                      <div className="text-lg md:text-2xl line-clamp-2 py-1 overflow-hidden text-ellipsis font-nunito lg:text-4xl sm:font-bold leading-[1.01] tracking-[-0.32px]">
                        {post.title}
                      </div>
                      <div
                        className="line-clamp-2 text-[14px] opacity-80"
                        dangerouslySetInnerHTML={{ __html: post.excerpt ?? "" }}
                      />
                    </div>
                    <div className="flex gap-2">
                      <img
                        src="/images/icons/24x24_solar.svg"
                        alt=""
                        className="h-[24px] w-[24px] object-cover object-center rounded-lg hover:cursor-pointer"
                      />
                      <span className="text-[#ED5C15]">
                        By Solar Enterprises
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>

          {/* Grid for next 3 blog posts */}
          <div className="grid grid-cols-1 sm:grid-cols-3 py-6 gap-6">
            {nextThreePosts.map((post, index) => (
              <div className="justify-between hover:rounded-[8px] p-1 hover:bg-white/10">
                <Link to={`/blogs/${post.slug}`} key={index}>
                  <div className="self-stretch">
                    <img
                      src="/images/icons/category.svg"
                      alt=""
                      className="absolute pl-4 pt-4 object-cover object-center rounded-lg hover:cursor-pointer"
                    />
                    <img
                      src={post.feature_image}
                      alt=""
                      className="h-[180px] w-[100%] object-cover object-center rounded-lg hover:cursor-pointer"
                    />
                  </div>
                  <div className="lg:h-[200px] flex flex-col items-start self-stretch sm:justify-between pt-6">
                    <div className="">
                      <div className="text-[14px] font-normal leading-130 opacity-40">
                        {formatDate(post.published_at)}
                      </div>
                      <div className="text-lg md:text-2xl line-clamp-2 py-1 overflow-hidden text-ellipsis font-nunito lg:text-4xl sm:font-bold leading-[1.01] tracking-[-0.32px]">
                        {post.title}
                      </div>
                      <div
                        className="line-clamp-2 text-[14px] opacity-80"
                        dangerouslySetInnerHTML={{ __html: post.excerpt ?? "" }}
                      />
                    </div>
                    <div className="flex gap-2">
                      <img
                        src="/images/icons/24x24_solar.svg"
                        alt=""
                        className="h-[24px] w-[24px] object-cover object-center rounded-lg hover:cursor-pointer"
                      />
                      <span className="text-[#ED5C15]">
                        By Solar Enterprises
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return sections;
  };

  const Noresult = () => {
    return (
      <div className="w-full">
        <div className="gap-6 w-[374px] mx-auto">
          <img src="/images/shapes/graph el.svg" alt="" className="mx-auto" />
          <div>
            <div className="text-white text-center font-nunito text-2xl font-bold leading-[1.2]">
              Nothing was found...
            </div>
            <div className="text-white/80 text-center font-nunito text-xs font-semibold leading-[1.2]">
              Unfortunately, nothing was found for your search Please try
              searching for something else
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="containerAlt lg:pt-8 ">
      <div className="items-center px-[20px] md:px-5 sb:px-10 xl:px-36 sm:flex sm:justify-between lg:w-full pt-6">
        <CategoryTabs activeCategory={category} setCategory={setCategory} />
        <div className=" justify-between py-4 sm:py-0 ">
          <div className="p-2 pl-4 border border-black/10 animate-input dark:border-white/10 h-[64px] rounded-full dark:bg-theme-bg bg-[#E8E8E8] flex items-center gap-2">
            <FaSearch className="text-gray-500 text-2xl" />
            <input
              type="text"
              className="bg-transparent outline-none w-full hover:placeholder:text-white dark:text-white text-black z-[1]"
              placeholder={isFocused ? "" : "Search by keyword"}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <div className="flex justify-end  h-[48px] w-[48px] rounded-full cursor-pointer">
              <div className="bg-[#ED5C15] rounded-full h-[48px] w-[48px] justify-center items-center flex dark:group-hover:bg-white group-hover:bg-theme-orange">
                <ArrowIcon className="h-[24px] w-[24px] md:w-[24px] md:h-[24px]  fill-theme-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-[20px] md:px-5 sb:px-10 xl:px-36 justify-between lg:w-full pt-6 md:pt-6">
        {keyword ? (
          <h1 className="text-sm">
            Found{" "}
            <span className="text-[#ED5C15]">
              {searchDocs.meta.pagination.total} Articles
            </span>
          </h1>
        ) : (
          <></>
        )}
        {searchDocs.meta.pagination.total ? renderBlogSections() : Noresult()}
        <Pagination
          activePage={activePage}
          itemsCountPerPage={limit}
          totalItemsCount={searchDocs?.meta?.pagination?.total}
          // pageRangeDisplayed={}
          onChange={handlePageChange}
          itemClass={`flex w-12 h-12 p-3 justify-center items-center gap-[10px] rounded-[16px] border border-white bg-black/3 backdrop-blur-[4px] hover:text-orange-900 pagination-item`}
          activeClass="bg-orange-500 text-white flex"
          itemClassPrev="hidden"
          itemClassNext="hidden"
          hideFirstLastPages={true}
          innerClass="flex flex-wrap gap-4 justify-center"
        />
      </div>
    </div>
  );
}
